import { lazy } from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import SubscriptionRouteGuard from "../auth/SubscriptionRouteGuard";
import { MagRoutes } from "../common/MagRoutes";
import RouteNotFound from "./RouteNotFound";
import GlobalError from "./GlobalError";
import StartExperienceWorkFlow from "../containers/StartExperienceWorkFlow";
import PhotoProfileWorkFlow from "../containers/PhotoProfileWorkFlow";
import axios from "axios";
import { AuthLayout } from "../auth/AuthLayout";
import RouteNonAuthGuard from "../auth/RouteNonAuthGuard";
import CornerLayout from "../auth/CornerLayout";
import NavBarComponent from "./Stepper";
import WorkFlowRouter from "../containers/UserWorkFlow";
import HaireWorkFlow from "../containers/HairWorkFlow";
import EyeWorkFlow from "../containers/EyeWorkFlow";
import TintWorkFlow from "../containers/TintWorkFlow";
import CompleteProfileGuard from "../auth/CompleteProfileGuard";
import ProcessResultComponent from "./ProcessResultComponent";
import EyePatternWorkFlow from "../containers/EyePatternWorkFlow";
import { ErrorBoundary } from "react-error-boundary";
import ServerUnvailableError from "../errors/ServerUnvailableError";
import DataFetchError from "../errors/DataFetchError";
import AnalysisErrorFallbackComponent from "../errors/AnalysisErrorFallbackComponent";
import CheckoutComponent from "../checkout/CheckoutComponent";
import CheckoutErrorComponent from "../checkout/CheckoutErrorComponent";

/* Public routes*/
const UserProfile = lazy(() => import("../containers/UserProfile/index"));
const Public = lazy(() => import("../components/Public"));
const Login = lazy(() => import("../auth/Login"));
const Register = lazy(() => import("../auth/Register/Register"));
const EmailVerification = lazy(() => import("../auth/EmailVerification"));
const ForgotPassword = lazy(() => import("../auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../auth/ResetPassword"));


const getUser = () => {
  const back = process.env.REACT_APP_BACKEND_URL + "/account/check-auth";
  return axios.get(back, { withCredentials: true });
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<ErrorBoundary fallback={<DataFetchError />}><AuthLayout /></ErrorBoundary>} loader={async () => { return await getUser(); }} errorElement={<ServerUnvailableError />}>
        <Route element={<RouteNonAuthGuard />}>
          <Route path={MagRoutes.Index} element={<Public />} />
        </Route>
        <Route element={<CornerLayout />}>
          <Route path={MagRoutes.checkoutError} element={<CheckoutErrorComponent />} />
          {/* secured routes, routes need a subscription*/}
          <Route element={<SubscriptionRouteGuard />}>
            <Route path="/analysis" element={<WorkFlowRouter />}>
              <Route element={<NavBarComponent />}>
                <Route path={MagRoutes.photoProfileWorkFlow} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><PhotoProfileWorkFlow /></ErrorBoundary>} />
                <Route path={MagRoutes.haireWorkFlow} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><HaireWorkFlow /></ErrorBoundary>} />
                <Route path={MagRoutes.eyeWorkFlow} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><EyeWorkFlow /></ErrorBoundary>} />
                <Route path={MagRoutes.eyePatternWorkFlow} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><EyePatternWorkFlow /></ErrorBoundary>} />
                <Route path={MagRoutes.tintWorkFlow} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><TintWorkFlow /></ErrorBoundary>} />
              </Route>
            </Route>
            {/* TODO add rule to redirect user if payment is success */}
            <Route path={MagRoutes.paymentForm} element={<CheckoutComponent />} />
            <Route path={MagRoutes.processResult} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><ProcessResultComponent /></ErrorBoundary>} />
            <Route path={MagRoutes.UserProfile} element={<CompleteProfileGuard />}>
              <Route path={MagRoutes.UserProfileHome} element={<ErrorBoundary FallbackComponent={AnalysisErrorFallbackComponent}><UserProfile /></ErrorBoundary>} />
            </Route>
          </Route>

          {/* public routes*/}
          {/* public can't be access by a none authenticated user*/}
          <Route element={<RouteNonAuthGuard />}>
            <Route path={MagRoutes.StartExperience} element={<StartExperienceWorkFlow />} />
            <Route path={MagRoutes.Login} element={<Login />} />
            <Route path={MagRoutes.Register} element={<Register />} />
            <Route path={MagRoutes.ForgotPassword} element={<ForgotPassword />} />
            <Route path={MagRoutes.ResetPassword} element={<ResetPassword />} />
            <Route path={MagRoutes.EmailVerification} element={<EmailVerification />} />
          </Route>

          <Route path={MagRoutes.GlobalError} element={<GlobalError />} />
          <Route path={MagRoutes.NotFound} element={<RouteNotFound />} />
        </Route>
      </Route>
    </>
  )
);
