import { useEffect } from "react";
import { useOutlet, useLoaderData } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { setUser, setUserProfile } from "./authSlice";
import { useFetchProfileQueryMutation } from "./authApiSlice";
import { selectIsAuthenticated } from "./selectors";
import { useSelector } from "react-redux";
import { useGetUserProgressQueryMutation } from "../containers/UserWorkFlow/userWorkflowApiSlice";
import { setPhotoProfileId } from "../features/photosManagment/sessionPhotosSlice";
import { useErrorBoundary } from "react-error-boundary";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { data } = useLoaderData() as any;
  const [fetchProfileQuery, { isLoading }] = useFetchProfileQueryMutation();
  const [getUserProgressQuery] = useGetUserProgressQueryMutation();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    dispatch(setUser({ emailAddress: data.emailAddress, userId: data.userId, isAuthenticated: data.isAuthenticated }));
  }, [data, dispatch]);


  useEffect(() => {
    const checkRequiredData = async () => {
      try {
        if (isAuthenticated) {
          const profile = await fetchProfileQuery(null).unwrap();
          dispatch(setUserProfile(profile));
          dispatch(setPhotoProfileId(profile.pictureId));
        }
      } catch (err) {
        showBoundary(err);
      }
    };
    checkRequiredData();
  }, [dispatch, fetchProfileQuery, getUserProgressQuery, isAuthenticated]);
  return (
    <>
      {outlet}
    </>
  );
};