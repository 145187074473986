"use client";
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { store } from './app/store';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import reportWebVitals from './reportWebVitals';
import './app/styles/index.scss';
// translations
import common_fr from "./app/assets/i18n/fr/common.json";
import common_en from "./app/assets/i18n/en/common.json";
import App from './App';
import { ErrorBoundary } from 'react-error-boundary';
import LayoutLoader from './app/components/LayoutLoader';
import { getUserBrowserLanguage } from './app/hooks/GetUserBrowserLanguageHook';

i18next.init({
  interpolation: { escapeValue: false },
  lng: getUserBrowserLanguage()!,
  resources: {
    en: {
      common: common_en
    },
    fr: {
      common: common_fr
    }
  }
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<h1>Global error boundary</h1>}>
      <Provider store={store} >
        <Suspense fallback={<LayoutLoader />}>
          <I18nextProvider i18n={i18next} >
            {/* <RouterProvider router={router} /> */}
            <App />
          </I18nextProvider>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();
